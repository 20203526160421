import { lazy } from "react";

const draftPreview = lazy(() => import("./campaign/DraftPreview"));
const EmbeddedForm = lazy(() => import("./signupforms/components/EmbeddedForms"));
const PopupContainer = lazy(() => import("./signupforms/components/PopupContainer"));
const OptInForms = lazy(() => import("./signupforms/components/OptInForms"));
const SurveyFormReport = lazy(() => import("./signupforms/components/SurveyFormReport"));
const CheckoutPage = lazy(() => import("./CheckoutPage/CheckoutPage"));
const OptoutForm = lazy(() => import("./optoutforms/components/OptoutForm"));
const OptoutFormList = lazy(() => import("./optoutforms/components/OptoutFormList"));
const UpdatePreferencesList = lazy(() => import("./optoutforms/components/updatePreferencesList"));
const UpdatePreferences = lazy(() => import("./optoutforms/components/updatePreferences"));
const Useroutform = lazy(() => import("./optoutforms/components/useroptoutform"));
const Thankyou = lazy(() => import("./optoutforms/components/ThankyouPage"));
const PollThankyou = lazy(() => import("./optoutforms/components/PollThankYouPage.js"));
const CampaignMainContainer = lazy(() => import("./campaign/CampaignMainContainer2"));
const IntermediateRoute = lazy(() => import("./campaign/intermediateRoute"));
const senderList = lazy(() => import("./campaign/senderList"));
const campaignTypeList = lazy(() => import("./campaign/campaignTypeList"));
const CampaignList = lazy(() => import("./campaign/campaignList"));
const MMCampaignList = lazy(() => import("./campaign/MMListView"));
const CampaignFooterSettings = lazy(() => import("./campaign/CampaignFooterSettingsV1"));
const CampaignFooterSettingsList = lazy(() => import("./campaign/CampaignFooterSettingsList"));
const InterestGroups = lazy(() => import("./intrstgrpsmgmnt/components/manageInterestGrps"));
const SegmentList = lazy(() => import("./segmentation/components/SegmentList"));
const Segments = lazy(() => import("./segmentation/components/Segments"));
const Templates = lazy(() => import("./templates/components/templateList"));
const preferences = lazy(() => import("./preferencecenter/components/preferencemanagement"));
const UpdatePrefPreview = lazy(() => import("./preferencecenter/components/updatePreferencePreview"));
const sessionout = lazy(() => import("./utilities/sessionout"));
const BeeEditor = lazy(() => import("./utilities/components/beeEditor"));
const PlainTextEditor = lazy(() => import("./utilities/components/PlainTextEditor"));
const campaignDetails = lazy(() => import("./campaign/CampaignDetails"));
const dashboard = lazy(() => import("./dashboard/Dashboard"));
const essidemenu = lazy(() => import("./utilities/components/essidemenu"));
const groupsMapping = lazy(() => import("./utilities/components/ContactInterestGroupsMapping"));
const interactions = lazy(() => import("./utilities/components/emailinteractions"));
const ErrorPage = lazy(() => import("./utilities/components/ErrorPage"));
const SetupPage = lazy(() => import("./utilities/components/SetupPage"));
const ThankyouPreview = lazy(() => import("./optoutforms/components/userThankyoupage"));
const JobDashboard = lazy(() => import("./jobdashboard/components/jobdashboard"));
const ImportList = lazy(() => import("./import/components/ImportList"));
const ProfileManagement = lazy(() => import("./profileManagement"));
const ImportSummary = lazy(() => import("./import/components/ImportSummary"));
const ImportUnsubscribers = lazy(() => import("./import/components/ImportUnsubscribers"));
const htmlUpload = lazy(() => import("./campaign/htmlUpload"));
const OptoutImportSummary = lazy(() => import("./import/components/optoutimportsummary"));
const ABSamplehtml = lazy(() => import("./campaign/AbsampleHtml"));
const ABReportSample = lazy(() => import("./campaign/AbReportHtml"));
const ABCampaingInnerReport = lazy(() => import("./campaign/ABCampaign/ABCampaingInnerReport"));
const WorkflowCampaignReport = lazy(() => import("./campaign/WorkflowCampaignReport"));
const WorkflowTest = lazy(() => import("./workflowManagement/WorkflowMainContainer"));
const Workflow = lazy(() => import("./workflowManagement/index"));
const WorkflowList = lazy(() => import("./workflowManagement/workflowReport/WorkflowList"));
const LandingPageList = lazy(() => import("./landingpages/list/LandingPageList"));
const CreateLandingPage = lazy(() => import("./landingpages/builder/LPBuilder"));
const LPTemplateList = lazy(() => import("./landingpages/LandingpageTemplates"));
const AILandingPages = lazy(() => import("./landingpages/AIGeneratedLP"));
const WebhookConfig = lazy(() => import("./webhookConfiguration/WebhookConfig"));
const PostWebhookConfig = lazy(() => import("./postWebhookSetup/PostWebhookConfig"));
const WebhookList = lazy(() => import("./webhookConfiguration/WebhookList"));
const ReputationDashboard = lazy(() => import("./reputationDashboard/ReputationDashboard"));
const MKMDashboard = lazy(() => import("./mkm/Dashboard"));
const TagManagement = lazy(() => import("./utilities/components/TagManagement"));
const DisclaimerManagement = lazy(() => import("./disclaimer/DisclaimerManagement"));
const ProfileFields = lazy(() => import("./profileFields/FieldList"));
const DisposableDomains = lazy(() => import("./disposableDomains/DisposableDomainsList.js"));
const ESAuth = lazy(() => import("./ESAuth"));
const ClientsReputationsummary = lazy(() => import("./reputationDashboard/ClientsReputationSummary"));
const RecentCampaigns = lazy(() => import("./reputationDashboard/RecentCampaigns"));
const EmailCampaignReport = lazy(() => import("./campaign/CampaignReport/EmailCampaignReport"));
const CheckoutMainContainer = lazy(() => import("./checkoutForms/index.js"));
const CheckoutFormsList = lazy(() => import("./checkoutForms/CheckoutFormsList"));
const DomainStatsList = lazy(() => import("./cyl-admin/domains/"));
const UserList = lazy(() => import("./cyl-admin/users/"));
const CYLDashboard = lazy(() => import("./cyl-admin/dashboard/"));
const RegularCampaignReport = lazy(() => import("./campaign/RegularCampaignReport"));
const ProfileUpdateList = lazy(() => import("./profileManagement/profileUpdateDelete"));
const FileExplorer = lazy(() => import("./landingpages/builder/FileExplorer"));
const BackgroundJobs = lazy(() => import("./backgroundJobs"));
const SubscriptionDashboard = lazy(() => import("./subscriptiondashboard"));
const ScheduleSummary = lazy(() => import("./scheduleSummary/ScheduleSummary"));
const SpamTrapsDashboard = lazy(() => import("./spamTraps/SpamTrapsSummary"));
//const ThemePage = lazy(() => import("./themePage/themePage"));
const EnrollmentDashboard = lazy(() => import("./enrollmentDashboard"));
const SummaryNotifications = lazy(() => import("./summaryNotifications/index"));
const SummaryNotificationPreview = lazy(() =>
  import("./workflowManagement/ExternalComponents/SummaryNotificationPreview")
);
const UserHasNoAccess = lazy(() => import("./utilities/accessDenied"));
const OrdersDashboard = lazy(() => import("./ordersDashboard"));
const WebsiteTagsSubTagsMain = lazy(() => import("./websiteTags-subTags/websiteTagsSubTagsMain"));
const SummaryNotificationCampaigns = lazy(() => import("./summaryNotifications/summaryNotificationCampaigns"));
const genericDashboard = lazy(() => import("./genericDashboard"));
const checkoutFormOrder = lazy(() => import("./Orders/CheckoutForm/index.js"));
const routes = [
  {
    path: "/embeddedForm/:formId/:selectedForm",
    component: EmbeddedForm,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/surveyFormReport",
    component: SurveyFormReport,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/checkoutpage",
    component: CheckoutPage,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/popupContainer/:formId/:clientId/:websiteId",
    component: PopupContainer,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/forms",
    component: OptInForms,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/OptoutForms",
    component: OptoutFormList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/OptoutForm/:formId",
    component: OptoutForm,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/updatePreferences/:upId",
    component: UpdatePreferences,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "updatePreferencesList",
    component: UpdatePreferencesList,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  { path: "/external/previewTemplate", component: draftPreview, uselayout: false, hasMKMAccessCheck: false },
  {
    path: "/external/useroptoutform",
    component: Useroutform,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  //this is duplicate route for useroptoutform
  {
    path: "/useroptoutform",
    component: Useroutform,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/external/thankyoupage",
    component: Thankyou,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  //this is duplicate route for thankyoupage
  {
    path: "/thankyoupage",
    component: Thankyou,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/external/mkmpollcampaign",
    component: PollThankyou,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/createcampaign",
    component: CampaignMainContainer,
    uselayout: true,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/intermediateRoute",
    component: IntermediateRoute,
    uselayout: true,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/managegroups",
    component: InterestGroups,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/templates",
    component: Templates,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/beeeditor",
    component: BeeEditor,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/external/managepreferences",
    component: preferences,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  //Duplicate route for managepreferences
  {
    path: "/managepreferences",
    component: preferences,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/updatePrefPreview",
    component: UpdatePrefPreview,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/campaignList",
    component: CampaignList,
    uselayout: true,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/campaignListView",
    component: MMCampaignList,
    uselayout: false,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/segmentList",
    component: SegmentList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/segmentListbi",
    component: SegmentList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/segments",
    component: Segments,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/sessionout",
    component: sessionout,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/campaignDetails",
    component: campaignDetails,
    uselayout: false,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/groupsmapping",
    component: groupsMapping,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/dashboard",
    component: dashboard,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/sidemenu",
    component: essidemenu,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/senderList",
    component: senderList,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/interactions",
    component: interactions,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/errorPage",
    component: ErrorPage,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/setupPage",
    component: SetupPage,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/external/userthankspage",
    component: ThankyouPreview,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  //Duplicate route for userthankspage
  {
    path: "/userthankspage",
    component: ThankyouPreview,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/jobdashboard",
    component: JobDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/campaignTypeList",
    component: campaignTypeList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/ImportList",
    component: ImportList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/profilemanagement",
    component: ProfileManagement,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/ImportSummary",
    component: ImportSummary,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/ImportUnsubscribers",
    component: ImportUnsubscribers,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/htmlUpload",
    component: htmlUpload,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/plaintexteditor",
    component: PlainTextEditor,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/optoutimportsummary",
    component: OptoutImportSummary,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/abtest",
    component: ABSamplehtml,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/abreporttest",
    component: ABReportSample,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/abinnerreport",
    component: ABCampaingInnerReport,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/workflowcampaignreport",
    component: WorkflowCampaignReport,
    uselayout: true,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/Workflow",
    component: Workflow,
    uselayout: false,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/WorkflowTest",
    component: WorkflowTest,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/WorkflowList",
    component: WorkflowList,
    uselayout: true,
    repNotification: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/landingpages",
    component: LandingPageList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/createlp",
    component: CreateLandingPage,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/lpTemplateList",
    component: LPTemplateList,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/AILandingPages",
    component: AILandingPages,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/webhookconfig",
    component: WebhookConfig,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/postWebhookConfig",
    component: PostWebhookConfig,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/webhookList",
    component: WebhookList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/mkmdashboard",
    component: MKMDashboard,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/managetags",
    component: TagManagement,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/reputationdashboard",
    component: ReputationDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/managedisclaimer",
    component: DisclaimerManagement,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/esauth",
    component: ESAuth,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/clientsreputationsummary",
    component: ClientsReputationsummary,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/recentcampaigns",
    component: RecentCampaigns,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/managefields",
    component: ProfileFields,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/disposabledomains",
    component: DisposableDomains,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/emailcampaignreport",
    component: EmailCampaignReport,
    uselayout: false,
    repNotification: true,
    hasMKMAccessCheck: true
  },

  {
    path: "/createcf-order",
    component: checkoutFormOrder,
    uselayout: false,
    hasMKMAccessCheck: true
  },

  {
    path: "/createcf",
    component: CheckoutMainContainer,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/checkoutForms",
    component: CheckoutFormsList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/checkoutForms-order",
    component: CheckoutFormsList,
    uselayout: true,
    hasMKMAccessCheck: true
  },

  {
    path: "/checkoutpages",
    component: LandingPageList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/domain-stats",
    component: DomainStatsList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/users-list",
    component: UserList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/cyl-dashboard",
    component: CYLDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/regularcampaignreport",
    component: RegularCampaignReport,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/profileUpdateDeleteList",
    component: ProfileUpdateList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/fileManager",
    component: FileExplorer,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/backgroundJobs",
    component: BackgroundJobs,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/CampaignFooterSettings",
    component: CampaignFooterSettings,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/CampaignFooterSettingsList",
    component: CampaignFooterSettingsList,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/subscriptionDashboard",
    component: SubscriptionDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/scheduleSummary",
    component: ScheduleSummary,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/spamTraps",
    component: SpamTrapsDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/enrollmentDashboard",
    component: EnrollmentDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/summaryNotifications",
    component: SummaryNotifications,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/external/summaryNotificationData",
    component: SummaryNotificationPreview,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/accessDenied",
    component: UserHasNoAccess,
    uselayout: false,
    hasMKMAccessCheck: false
  },
  {
    path: "/ordersDashboard",
    component: OrdersDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/websitetags-subtags-mapping",
    component: WebsiteTagsSubTagsMain,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/summaryNotificationCampaigns",
    component: SummaryNotificationCampaigns,
    uselayout: false,
    hasMKMAccessCheck: true
  },
  {
    path: "/genericDashboard",
    component: genericDashboard,
    uselayout: true,
    hasMKMAccessCheck: true
  },
  {
    path: "/checkoutpage-order",
    component: CheckoutPage,
    uselayout: true,
    hasMKMAccessCheck: true
  }

  //route for theme page--as of now we commented--may be used in future
  // {
  //   path: "/theme",
  //   component: ThemePage,
  //   uselayout: true
  // },
  // {
  //   path: "/theme/:themeId",
  //   component: ThemePage,
  //   uselayout: true
  // }
];

export default routes;
