const importFields = {
  availableFields: ["emailId", "firstName", "lastName", "phone", "company", "title", "repemailid"],
  acptdHeadersFirstName: ["firstname", "first name", "fname"],
  acptdHeadersEmail: ["email", "emailid", "email id", "emailaddress", "email address", "emails"],
  acptdHeadersLastName: ["lastname", "last name", "lname"],
  acptdHeadersPhone: ["phone", "phone no", "phone number", "mobile"],
  acptdHeadersCompany: ["company", "company name"],
  acptdHeadersTitle: ["title", "title name", "job title"],
  acptdHeadersRep: ["rep emailid", "rep email id", "repemailid"],
  acptdHeadersaddress1: ["address", "address1", "line1"],
  acptdHeadersaddress2: ["address2", "address2", "line2"],
  acptdHeadersCity: ["city", "city name", "cityname"],
  acptdHeadersState: ["state", "statename"],
  acptdHeadersCountry: ["country", "country name", "countryname"],
  acptdHeadersCounty: ["county", "county name", "countyname"],
  acptdHeadersZipCode: ["zip code", "zip", "pin", "pin code", "zipcode", "pincode", "code"],
  allParameters: [
    "firstname",
    "first name",
    "email",
    "emailid",
    "email id",
    "emailaddress",
    "email address",
    "lastname",
    "last name",
    "phone",
    "phone no",
    "phone number",
    "company",
    "company name",
    "title",
    "title name",
    "rep emailid",
    "rep email id",
    "repemailid"
  ]
};

const workflowStatus = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
  DELETE: "Delete",
  UNPUBLISHED: "Unpublished",
  FAILED: "Failed",
  SCHEDULED: "Scheduled"
};

const workflowHistoryStatus = {
  COMPLETED: "Completed",
  DROPPED: "Dropped",
  TERMINATED: "Terminated",
  FAILED: "Failed",
  INPROCESS: "InProcess"
};

const wfActiveInactiveProcessStatus = {
  PENDING: "Pending",
  INPROCESS: "InProcess",
  COMPLETED: "Completed",
  FAILED: "Failed"
};

const workflowTabs = {
  systemWorkflows: "System Workflows",
  defaultWorkflows: "Default Workflows",
  repNotifications: "Rep Notifications",
  salesWorkflows: "Sequence Workflows",
  customWorkflows: "Custom Workflows",
  inActiveWorkflows: "Inactive Workflows"
};

const checkoutFormStatus = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  REPLICATE: "Replicated",
  DELETE: "Delete"
};

// For Admin Access Control
const userRoles = {
  SUPERADMIN: "SuperAdmin",
  ADMIN: "Admin",
  USER: "User"
};

// There values should match with mongo DB values as of now
const accessControls = {
  optInForms: {
    enableDisclaimer: "Enable disclaimer section in froms"
  },
  optOuts: {
    enableOptouts: "Enable opt-outs"
  }
};

const OTHER_MERGE_TAGS = [
  {
    name: "ID",
    value: "<% CustomerID %>",
    key: "CustomerID"
  },
  {
    name: "Rep name",
    value: "<% repName %>",
    key: "repName"
  },
  {
    name: "Time trade link",
    value: "<% timeTradeLink %>",
    key: "timeTradeLink"
  },
  {
    name: "Current Stage",
    value: "<% order_Stage %>",
    key: "order_Stage"
  },
  {
    name: "Previous Stage",
    value: "<% order_PreviousStage %>",
    key: "order_PreviousStage"
  },
  {
    name: "Social Media Link",
    value: "<% SocialMediaLink %>",
    key: "SocialMediaLink"
  },
  {
    name: "Campaign ID",
    value: "<% cmpid %>",
    key: "cmpid"
  },
  {
    name: "Rep Phone Number",
    value: "<% RepPhoneNumber %>",
    key: "RepPhoneNumber"
  }
];

const checkoutPageLayouts = [
  { display: "Multiple Tabs", value: 1 },
  { display: "Single Page", value: 2 }
];

const layouts = [
  { display: "Single Column", value: 1 },
  { display: "Two Column", value: 2 },
  { display: "Horizontal", value: 3 }
];

const checkoutFormLayouts = [
  { display: "Cards", value: "show" },
  { display: "CheckBoxes", value: "hide" }
];

const checkoutFormPerUnitLabels = [
  {
    display: "Show",
    value: "show"
  },
  { display: "Hide", value: "hide" }
];

const checkoutFormPlanSelection = [
  {
    display: "Single",
    value: "show"
  },
  { display: "Multiple", value: "hide" }
];

const getFormattedSource = (source) => {
  let formattedSource;
  switch (source) {
    case "addProfile":
      formattedSource = "Add Profile";
      break;
    case "import":
      formattedSource = "Import";
      break;
    case "crm":
      formattedSource = "CRM Contact";
      break;
    case "webhook":
      formattedSource = "Webhook Submission";
      break;
    case "conversion":
      formattedSource = "Conversion";
      break;
    case "chargeBrite":
      formattedSource = "ChargeBrite";
      break;
    default:
      formattedSource = source;
      break;
  }
  return formattedSource;
};

const resendCampaignDays = [
  { days: "2 days", value: 2 },
  { days: "3 days", value: 3 },
  { days: "4 days", value: 4 },
  { days: "5 days", value: 5 },
  { days: "6 days", value: 6 },
  { days: "7 days", value: 7 },
  { days: "8 days", value: 8 },
  { days: "9 days", value: 9 },
  { days: "10 days", value: 10 }
];

const DEBOUNCING_DELAY = 700;
const variateIndex = { 1: "A", 2: "B", 3: "C", W: "W" };
const REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP = "This option not available when only rep notifications enabled";
const DEFAULT_VIDEO_TAG = `<iframe width="560" height="315" src="PUT YOUR EMBED VIDEO URL HERE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
const BOT_URL_GUID = "6c7c30ea-5fcc-49c3-84a0-167817900671";
const BOT_IDENTIFICATION_URL = "https://www.mirabeltechnologies.com/link/" + BOT_URL_GUID;
const TRIGGER_CATEGORY_TYPES = {
  REGULAR: "Regular",
  SALES_SEQUENCE: "SalesSequence",
  PREVIEW: "Preview",
  SUMMARY_NOTIFICATIONS: "SummaryNotifications"
};

const themeSectionType = {
  ThemeId: "themeId",
  ThemeName: "themeName",
  Heading: "Heading",
  Text: "Text",
  Button: "Button",
  Checkbox: "Checkbox",
  Card: "Card",
  Border: "Border",
  Link: "Link",
  Error: "Error",
  Image: "Image"
};

const enumAddressLookupType = {
  STREET_ADDRESS: 1,
  Zipcode: 2
};
const singlePlanSelections = [
  { display: "Skip for single plan", value: "skip" },
  { display: "Show single plan", value: "show" }
];

const frequency = [
  { id: "days", value: "Day(s)" },
  { id: "weeks", value: "Week(s)" },
  { id: "months", value: "Month(s)" }
];
const RSS_FREQUENCY = [
  { id: "days", value: "Daily" },
  { id: "weeks", value: "Weekly" },
  { id: "months", value: "Monthly" }
];

const monthDays = [
  { id: "1", value: "1" },
  { id: "2", value: "2" },
  { id: "3", value: "3" },
  { id: "4", value: "4" },
  { id: "5", value: "5" },
  { id: "6", value: "6" },
  { id: "7", value: "7" },
  { id: "8", value: "8" },
  { id: "9", value: "9" },
  { id: "10", value: "10" },
  { id: "11", value: "11" },
  { id: "12", value: "12" },
  { id: "13", value: "13" },
  { id: "14", value: "14" },
  { id: "15", value: "15" },
  { id: "16", value: "16" },
  { id: "17", value: "17" },
  { id: "18", value: "18" },
  { id: "19", value: "19" },
  { id: "20", value: "20" },
  { id: "21", value: "21" },
  { id: "22", value: "22" },
  { id: "23", value: "23" },
  { id: "24", value: "24" },
  { id: "25", value: "25" },
  { id: "26", value: "26" },
  { id: "27", value: "27" },
  { id: "28", value: "28" },
  { id: "29", value: "29" },
  { id: "30", value: "30" },
  { id: "31", value: "31" }
];

const weekdays = [
  { id: 0, value: "Sunday" },
  { id: 1, value: "Monday" },
  { id: 2, value: "Tuesday" },
  { id: 3, value: "Wednesday" },
  { id: 4, value: "Thursday" },
  { id: 5, value: "Friday" },
  { id: 6, value: "Saturday" }
];

const customisedFrequency = [
  { id: 0, value: "1st" },
  { id: 1, value: "2nd" },
  { id: 2, value: "5th" },
  { id: 3, value: "10th" },
  { id: 4, value: "15th" },
  { id: 5, value: "20th" },
  { id: 6, value: "25th" }
];

const popUpPageFrequency = [
  { id: 0, value: "1" },
  { id: 1, value: "2" },
  { id: 2, value: "3" },
  { id: 3, value: "4" },
  { id: 4, value: "5" },
  { id: 5, value: "10" }
];
const popUpPageDuration = [
  { id: 0, value: "1" },
  { id: 1, value: "2" },
  { id: 2, value: "5" },
  { id: 3, value: "10" },
  { id: 4, value: "20" },
  { id: 5, value: "30" },
  { id: 6, value: "60" }
];

const summaryNotificationSender = {
  ADDRESS: "Mirabel Technologies Inc.\n1401 E.Broward Blvd., #206\nFt Lauderdale, Florida 33301",
  NAME: "Admin at Mirabel Technologies",
  Email: "admin@mirabeltechnologies.com"
};

const LoadingWaitTexts = {
  START: [
    "Loading the magic ✨",
    "Sit back and relax, we're on it.",
    "Sit tight, we're making digital magic happen.",
    "Grab a snack, we're working hard behind the scenes.",
    "Our creativity engines are firing up!",
    "We're igniting the spark of imagination.",
    "Stay tuned for a surprise in the making.",
    "Envisioning the extraordinary, one pixel at a time.",
    "Creating a digital masterpiece just for you.",
    "Breathe in, breathe out, and let us work our magic.",
    "Pouring creativity into every byte."
  ],
  MID: [
    "Hang tight, we're doing the heavy lifting.",
    "The magic is in progress, please wait.",
    "We appreciate your patience while we work our magic.",
    "Pixels in motion, stay tuned.",
    "In the lab, brewing up a storm of creativity.",
    "Our digital forge is hard at work.",
    "Beneath the surface, something marvelous stirs.",
    "In the midst of pixel perfection.",
    "We're stitching together something incredible."
  ],
  END: [
    "Stay with us!",
    "Hang on, you're almost there!",
    "Just a little more patience, please!",
    "Waiting for the grand reveal? So are we!",
    "Your experience is getting a final polish.",
    "The magic is about to unfold.",
    "Hold on tight, we're racing towards perfection!",
    "Your experience is in the oven, almost ready!",
    "The finishing touches are being applied.",
    "Polishing the pixels for a flawless experience.",
    "The pixels are aligning for something special.",
    "Almost there, but remember, good things take time."
  ]
};

const lpTypes = {
  LANDING_PAGE: "Landing Page",
  POPUP_PAGE: "Pop Up Page",
  CHECKOUT_PAGE: "Checkout Page"
};

const campStatus = {
  DRAFT: "Draft",
  SENT: "Sent",
  FAILED: "Failed",
  SCHEDULED: "Scheduled"
};

const campType = {
  REGULAR: "Regular",
  AB: "AB",
  WORKFLOW: "Workflow",
  MAILCHIMP: "MailChimp",
  RSS_Feed: "RSS Feed",
  POLL: "Poll"
};

const COMPANY = "company";
const CRM = "crm";

const tagsAsCheckBox = "tagsascheckbox";
const uploadFileAsPf = "uploadFilePf";

const DEFAULT_CAMPAIGN_VIEW_ID = "9991";

const htmlContentTypes = {
  plaintext: "Plain Text",
  bee: "Designer",
  html: "HTML"
};

const RssCFreqTypes = {
  days: "Daily",
  weeks: "Weekly",
  months: "Monthly"
};

const RefColumnsForSorting = {
  RNO_SCD: "resendCampaignData.scheduledDate",
  RNO_SED: "resendCampaignData.lastProcessedOn",
  RNC_SCD: "resendToOpenButNotClicked.scheduledDate",
  RNC_SED: "resendToOpenButNotClicked.lastProcessedOn",
  TestVariant: "abCampaignData.variateName",
  RssCFreq: "rssFeedObject.selectedFrequency",
  RssArticleCount: "rssFeedObject.articleCount"
};

const CAMPAIGN_ANALYTICS = "CAMPAIGN_ANALYTICS";

const DownloadMultipleFiles = {
  Success_Text: "Your downloadable link has been generated successfully.",
  Click_Text: "Click here to download."
};

const mimeTypes = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  svg: "image/svg+xml",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain"
};
const showAndHideSubscriberForms = [
  { display: "Show Subscriber Form", value: "showSubscriberForm" },
  { display: "Go To Summary", value: "showPurchaseSummary" }
];

const keysToDisplayWithCommas = ["recipientsCount", "totalClicks", "uniqueClicks", "opensCount", "clicksCount", "oC"];

const optOutForms = {
  POLL_THANK_YOU_PAGE: "pollThankYouPage",
  EXPIRED_POLL_THANK_YOU_PAGE: "expiredPollThankYouPage",
  OPTOUT_FORM: "optOutform"
};

module.exports = {
  DEBOUNCING_DELAY,
  frequency,
  monthDays,
  weekdays,
  customisedFrequency,
  RSS_FREQUENCY,
  popUpPageFrequency,
  popUpPageDuration,
  importFields,
  workflowStatus,
  workflowTabs,
  workflowHistoryStatus,
  userRoles,
  accessControls,
  OTHER_MERGE_TAGS,
  getFormattedSource,
  checkoutFormStatus,
  layouts,
  checkoutPageLayouts,
  resendCampaignDays,
  variateIndex,
  REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP,
  DEFAULT_VIDEO_TAG,
  BOT_IDENTIFICATION_URL,
  TRIGGER_CATEGORY_TYPES,
  themeSectionType,
  enumAddressLookupType,
  BOT_URL_GUID,
  singlePlanSelections,
  summaryNotificationSender,
  LoadingWaitTexts,
  lpTypes,
  campStatus,
  campType,
  tagsAsCheckBox,
  uploadFileAsPf,
  DEFAULT_CAMPAIGN_VIEW_ID,
  htmlContentTypes,
  RssCFreqTypes,
  RefColumnsForSorting,
  CAMPAIGN_ANALYTICS,
  COMPANY,
  CRM,
  DownloadMultipleFiles,
  mimeTypes,
  wfActiveInactiveProcessStatus,
  showAndHideSubscriberForms,
  keysToDisplayWithCommas,
  optOutForms,
  checkoutFormLayouts,
  checkoutFormPerUnitLabels,
  checkoutFormPlanSelection
};
