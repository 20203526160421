import moment from "moment";
import CryptoJS from "crypto-js";
import { apiCall } from "./apiCall";
import { BOT_URL_GUID, mimeTypes } from "./enums";
import { RSS_FEED_MERGETAGS } from "./configs/bee.config";
import { showAlert } from "./alertmessage";
import axios from "axios";
export const formatDate = (date) => moment(date).format("MMM D YYYY, h:mm A");
export const formatDateYYYMMDD = (date) => moment(date).format("YYYY-MM-DD");
export const setZeroTimeToDate = (date) => moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
export const clientSpecificDate = (date) =>
  moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes").format("MMM D YYYY, h:mm A");

export const getUTCDate = (date) => moment.utc(date).format("MMM D YYYY, h:mm A");
export const getFrequencyDateRange = (frequencyType) => {
  const now = new Date();
  const toDaydate = now.getDate();
  let fromDate, toDate;
  if (frequencyType === "t") {
    fromDate = now;
    toDate = now;
  } else if (frequencyType === "y") {
    fromDate = new Date(moment().subtract(1, "days"));
    toDate = new Date(moment().subtract(1, "days"));
  } else if (frequencyType === "tw") {
    const dayOfNow = now.getDay();
    fromDate = new Date(moment().subtract(dayOfNow, "days"));
    toDate = now;
  } else if (frequencyType === "lw") {
    const dayOfNow = now.getDay();
    fromDate = new Date(moment().subtract(dayOfNow + 7, "days"));
    toDate = new Date(moment().subtract(dayOfNow + 1, "days"));
  } else if (frequencyType === "tm") {
    fromDate = new Date(moment().subtract(toDaydate - 1, "days"));
    toDate = now;
  } else if (frequencyType === "lm") {
    fromDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    toDate = new Date(moment().subtract(toDaydate, "days"));
  }
  return { fromDate, toDate };
};
const ENV_PRODUCTION = "production";
const MM_DOMAIN_URL = "magazinemanager";
const MKM_DOMAIN_URL = "mirabelsmarketingmanager";
const NM_DOMAIN_URL = "newspapermanager";
export const abWinnerSendDate = (date) =>
  moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes").format("DD-MMM-YYYY, h:mm A");
let _mmDomain, _mkmDomain, _nmDomain;
if (process.env.REACT_APP_ENV === ENV_PRODUCTION) {
  _mmDomain = "https://mrktapp.magazinemanager.com/";
  _mkmDomain = "https://app.mirabelsmarketingmanager.com/";
  _nmDomain = "https://mrktapp.newspapermanager.com/";
} else if (process.env.REACT_APP_ENV === "staging") {
  _mmDomain = "https://stgmrktapp.magazinemanager.biz/";
  _mkmDomain = "https://stgmrktapp.mirabelsmarketingmanager.biz/";
  _nmDomain = "https://stgmrktapp.newspapermanager.biz/";
} else if (process.env.REACT_APP_ENV === "smoke") {
  _mmDomain = "https://t1mrktapp.magazinemanager.com/";
  _mkmDomain = "https://t1mrktapp.mirabelsmarketingmanager.com/";
  _nmDomain = "https://t1mrktapp.newspapermanager.com/";
} else {
  _mmDomain = "https://t1mrktapp2.magazinemanager.com/";
  _mkmDomain = "https://t1mrktapp2.mirabelsmarketingmanager.com/";
  _nmDomain = "https://t1mrktapp2.newspapermanager.com/";
}
export const mmDomain = _mmDomain;
export const mkmDomain = _mkmDomain;
let _EmailServiceAppUrl = "",
  _AccountAuthURL = "",
  _PortalURL = "";

if (process.env.REACT_APP_ENV === "production") {
  _EmailServiceAppUrl = "https://emailservice.mirabelsmarketingmanager.com/";
  _AccountAuthURL = "https://accounts-auth.mirabeltechnologies.com/";
  _PortalURL = "https://portal.mirabeltechnologies.com/";
} else if (process.env.REACT_APP_ENV === "staging") {
  _EmailServiceAppUrl = "https://stgemailservice.magazinemanager.biz/";
  _AccountAuthURL = "https://accounts-authstaging.mirabeltechnologies.com/";
  _PortalURL = "https://portal.mirabeltechnologies.de/";
} else if (process.env.REACT_APP_ENV === "smoke") {
  _EmailServiceAppUrl = "https://t1emailservice.magazinemanager.com/";
  _AccountAuthURL = "http://accounts-authtier1.mirabeltechnologies.com/";
  _PortalURL = "http://tier1-portal2.mirabeltechnologies.com/";
} else {
  _EmailServiceAppUrl = "https://t1emailservice2.magazinemanager.com/";
  // _EmailServiceAppUrl = `http://localhost:8088`;
  _AccountAuthURL = "https://accounts-authdev.mirabeltechnologies.com/";
  _PortalURL = "https://mirabeldev-portal.mirabeltechnologies.com/";
}

export const EmailServiceAppUrl = _EmailServiceAppUrl;
export const CPAccountAuthURL = _AccountAuthURL;
export const CPAccountSettingsURL = `${_AccountAuthURL}intranet/Members/Account/AccountSettings.aspx?product=MSCP`;
export const CustomerPortalURL = _PortalURL;
export const AMAZON_S3_IMAGE_URL = "https://mmclientfilespublic.s3.amazonaws.com/";
export const CAMPAIGN_TYPE_LIMIT = 20;
export const CAMPAIGN_CONTENT_DEFAULT_TEXT = "lorem ipsum dolor sit amet";
export const TEST_CAMPAIGN_RECIPIENT_SELECT_LIMIT = 10;
export const TEST_CAMPAIGN_RECIPIENT_LIST_LIMIT = 100;
export const EXPORT_COUNT_LIMIT = 200000;
export const numberWithCommas = (number) =>
  isNaN(number) ? 0 : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getDomainUrl = () => {
  let mkmInteractionsURL = _mmDomain;
  const isMKM = window.location.hostname.includes("mirabelsmarketingmanager");
  const isNM = window.location.hostname.includes("newspapermanager");
  if (isMKM && _mkmDomain) {
    mkmInteractionsURL = _mkmDomain;
  } else if (isNM && _nmDomain) {
    mkmInteractionsURL = _nmDomain;
  }
  return mkmInteractionsURL;
};
export const getStepCount = (workflowStats, stepId) => {
  let profileCount = 0;
  if (workflowStats && workflowStats.length > 0) {
    for (const stat of workflowStats) {
      if (stat.stepId == stepId) {
        profileCount = stat.count;
        break;
      }
    }
  }
  return profileCount;
};

export const getTimezoneString = (timeZoneMinutes, actualTimeZone) => {
  let timeZone = window["actualTimeZone"] ? window["actualTimeZone"] : actualTimeZone;
  return timeZoneMinutes == 0
    ? "GMT + 00:00"
    : timeZoneMinutes < 0
    ? `GMT - ${moment.utc().startOf("day").add(Math.abs(timeZoneMinutes), "minutes").format("hh:mm")} (${timeZone})`
    : `GMT + ${moment.utc().startOf("day").add(timeZoneMinutes, "minutes").format("hh:mm")} (${timeZone})`;
};

// prettier-ignore
export const getHyphenatedURLPath = (url) =>
  url
    .replace(/^\//, "") // Omit forward slash (`/`) in the beginning
    .replace(/[^a-zA-Z 0-9-_]+/g, "")
    .trim() // Remove spaces from both ends
    .replace(/\s+/g, "-") // Replace all the between spaces with hyphens
    .toLowerCase(); // Convert the path to lowercase

export const toProperCase = (text) =>
  text
    ? text.replace(/\w\S*/g, function (text) {
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
      })
    : "";

export const receiver = (e) => {
  let postData = e.data;
  if (typeof postData == "object") {
    const action = postData.Action;
    if (action === "WebsiteChange") {
      const obj = {
        websiteID: postData.Data.WebsiteID
      };
      return apiCall("/api/UpdateCurrentWebsiteID", "POST", obj);
    } else if (action == "Logout") {
      sessionStorage.removeItem("ACCESS_CONTROL");
      apiCall("/api/logout");
    }
  }
};
export const dateRangeOptions = {
  t: "Today",
  y: "Yesterday",
  tw: "This Week",
  lw: "Last Week",
  tm: "This Month",
  lm: "Last Month",
  between: "Between"
};
export const numberRangeOptions = {
  e: "Equal to",
  lt: "Less than",
  gt: "Greater than",
  lte: "Less than equal to",
  gte: "Greater than equal to",
  between: "Between",
  ne: "Not equal to"
};
export const contractDateRangeOptions = {
  expiry: "Expiring / Expired",
  current_future: "Current or Future",
  any_order: "Any Orders",
  no_order: "No Orders"
};
export const subContractDateRangeOptions = {
  on: "On",
  after: "After",
  before: "Before",
  between: "Between",
  in_next: "In next",
  in_last: "In last"
  // "in_next_days": "in next __ days",
  // "in_next_weeks": "in next __ weeks",
  // "in_next_months": "in next __ months",
  // "in_last_days": "in last __ days",
  // "in_last_weeks": "in last __ weeks",
  // "in_last_months": "in last __ months"
};
export const daysWeeksMonthsOptions = {
  days: "Days",
  weeks: "Weeks",
  months: "Months"
};

export const dateRangeFilterOptions = {
  before: "Before",
  after: "After"
};

export const daysWeeksOptions = {
  days: "Days",
  weeks: "Weeks"
};

export const dateProperties = [
  {
    display: "Order Expiration Date",
    value: "oexpDt"
  },
  {
    display: "Order Start Date (Ad Material)",
    value: "startDate"
  },
  {
    display: "Order Created",
    value: "dateAdded"
  },
  {
    display: "Ad Material Deadline Date",
    value: "DeadlineDate"
  },
  {
    display: "Late Notice",
    value: "DueDate"
  },
  {
    display: "Project Start Date",
    value: "ProjectStartDate"
  },
  {
    display: "Project Completed Date",
    value: "ProjectEndDate"
  },
  {
    display: "Due Date",
    value: "OrderDueDate"
  }
];

export const OrdersProductionProperties = [
  {
    display: "Assigned To",
    value: "AssignedTo"
  },
  {
    display: "Priority",
    value: "Priority"
  },
  {
    display: "Designer Change",
    value: "Designer"
  },
  {
    display: "File Upload",
    value: "FileName"
  },
  {
    display: "Ad Pickup",
    value: "PickupContractID"
  },
  {
    display: "Requires Design",
    value: "RequiresDesign"
  },
  {
    display: "Production Note Added",
    value: "ProductionNote"
  }
];
export const subscriptionDateProperties = [
  {
    display: "Subscription Created Date",
    value: "CreatedOn"
  },
  {
    display: "Subscription Start Date",
    value: "StartDate"
  },
  {
    display: "Subscription End Date",
    value: "SubscriptionEndDate"
  },
  {
    display: "Subscription Billing Date",
    value: "NextBillingAt"
  },
  {
    display: "Subscription Trial Start Date",
    value: "TrialStartDate"
  },
  {
    display: "Subscription Trial End Date",
    value: "TrialEndDate"
  },
  {
    display: "Subscription Renewal Date",
    value: "RenewalDate"
  },
  {
    display: "Subscription Cancelled Date",
    value: "ToBeCancelled"
  },
  {
    display: "Subscription Pause Date",
    value: "ToBePaused"
  },
  {
    display: "Subscription Resume Date",
    value: "ToBeResumedOn"
  }
];

export const subscriptionDateFields = {
  StartDate: "Subscription Start Date",
  SubscriptionEndDate: "Subscription End Date",
  NextBillingAt: "Subscription Billing Date",
  TrialStartDate: "Subscription Trial Start Date",
  TrialEndDate: "Subscription Trial End Date",
  RenewalDate: "Subscription Renewal Date",
  ToBeCancelled: "Subscription Cancelled Date",
  ToBePaused: "Subscription Pause Date",
  ToBeResumedOn: "Subscription Resume Date",
  startDate: "Order Start Date",
  oexpDt: "Order Expiration Date",
  CreatedOn: "Subscription Created Date"
};

export const statusProperties = [
  {
    display: "Renewed",
    value: "Renewed"
  },
  {
    display: "Cancelled",
    value: "Cancelled"
  },
  {
    display: "Paused",
    value: "Pause"
  },
  {
    display: "Resumed",
    value: "Resume"
  }
];
export const subscriptionCancellationStatusProperties = [
  // {
  //   display: "Initiated",
  //   value: "Initiated"
  // },
  {
    display: "Removed",
    value: "Removed"
  }
];
export const displayDaysWeeksMonths = {
  days: "day(s)",
  weeks: "week(s)"
};

export const getContractDateRange = (frequencyType) => {
  const now = new Date();
  let fromDate = now,
    toDate = now;

  if (frequencyType === "cf") {
    fromDate = now;
  } else if (frequencyType === "ep") {
    fromDate = new Date(moment().subtract(1, "days"));
  }
  return { fromDate, toDate };
};

export const decimalWithCommas = function (number = 0) {
  return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const formatAmountWithCurrency = function (amount, currencySymbol) {
  if (amount === "") {
    return "";
  }
  return amount < 0
    ? `(${currencySymbol || ""}${decimalWithCommas(Math.abs(amount))})`
    : `${currencySymbol || ""}${decimalWithCommas(amount)}`;
};

export const formatAmountWithCurrencyInSummaryPage = function (
  amount,
  currencySymbol,
  totalSubscribers = 1,
  isCostDetailsEnabled
) {
  if (amount === "") {
    return "";
  }
  // required for totalSubscribers > 1 case, ex: $150 ($50 x 3)
  const actualAmtStr = `${currencySymbol || ""}${decimalWithCommas(amount)}`;
  const subscribersAmtText =
    totalSubscribers > 1 && isCostDetailsEnabled ? ` (${actualAmtStr} x ${totalSubscribers})` : "";

  const totalAmount = amount * totalSubscribers;
  return totalAmount < 0
    ? `(${currencySymbol || ""}${decimalWithCommas(Math.abs(totalAmount))})${subscribersAmtText}`
    : `${currencySymbol || ""}${decimalWithCommas(totalAmount)}${subscribersAmtText}`;
};

export const crmEncryptDecryptKey = "$%x@#~$a";
export const authEncryptDecryptKey = "20SA16ED";
export const AUTO_SAVE_RELOAD_MILLI_SECONDS = 15000;
export const ACTIVE_USERS_CHECK_MILLI_SECONDS = 10000;
export const MAX_UPLOADED_FILES = 5;

export const encryptByDES = (message, key) => {
  let keyHex = CryptoJS.enc.Utf8.parse(key);
  let ivHex = CryptoJS.enc.Utf8.parse(key);
  keyHex = CryptoJS.SHA1(keyHex);
  ivHex = CryptoJS.SHA1(ivHex);
  // CryptoJS use CBC as the default mode, and Pkcs7 as the default padding scheme
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.CBC,
    iv: ivHex,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
};
export const getESTDate = (date, flag) => {
  let offset = window.estDateOffset;
  if (flag == "heading") {
    return moment.utc(date).add(offset, "minutes").format("MMM D YYYY");
  } else {
    return moment.utc(date).add(offset, "minutes").format("MMM D YYYY, h:mm A");
  }
};

export const isFieldEmpty = (field) => {
  if (field === "" || field === null || field === undefined || (Array.isArray(field) && field.length == 0)) {
    return true;
  }
  return false;
};

export const getTextFromHTML = function (htmlContent) {
  let a = document.createElement("span");
  a.innerHTML = htmlContent;
  return a.textContent;
};
export const checkEmailMasked = function (email) {
  return email.includes("*");
};
export const restrictTwoDecimalPlaces = function (number) {
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};
// function from https://stackoverflow.com/a/5624139/3695983
function hexToRgb(hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

// function from https://stackoverflow.com/a/9733420/3695983
function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const calculateContrastRatio = function (color1, color2) {
  const color1rgb = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);

  // calculate the relative luminance
  const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b);
  const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b);

  // calculate the color contrast ratio
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05);

  return ratio;
};

export const repColorCodes = [
  "#469FBB",
  "#FF5733",
  "#C81549",
  "#0B831C",
  "#A8B41C",
  "#0C475A",
  "#900C3F",
  "#27AE60",
  "#f80c9c",
  "#9064DF"
];

export const getShortName = (name) => {
  let shortName = "";
  if (name) {
    const arrayNames = name.split(" ");
    if (arrayNames.length >= 2) {
      shortName = arrayNames[0].substr(0, 1) + arrayNames[1].substr(0, 1);
    } else if (arrayNames.length == 1) {
      shortName = arrayNames[0].substr(0, 1);
    }
  }
  return shortName.toUpperCase();
};
export const groupBy = (data, key) => {
  return data.reduce((acc, x) => {
    acc[x[key]] = [...(acc[x[key]] || []), x];
    return acc;
  }, {});
};
export const API_TIMEOUT = 5 * 60 * 1000;
export const MODAL_TIMEOUT_SECONDS = 30;

export const extractCampaignContentLinks = (htmlContent) => {
  let campaignContentLinks = [];
  let hrefs = [];
  let divDoc = document.createElement("div");
  divDoc.innerHTML = htmlContent;
  const html = divDoc.innerHTML;
  const anchors = divDoc.querySelectorAll("a");
  Array.from(anchors).forEach(function (anchor) {
    let { href } = anchor;
    if (href) {
      // For manually created Links , Line number 222 will automatically add slash at the end which is not part of html Content , so removing slash for those links(this is not only the reason for removal apart from this enrollments not happening because of the check)
      if (!html.includes(href) && href.charAt(href.length - 1) == "/") {
        href = href.slice(0, -1);
      }
      href = href.replace("https://www.", "").replace("http://www.", "").replace("https://", "").replace("http://", "");

      const queryStringStartIndex = href.indexOf("?");
      if (queryStringStartIndex > -1) href = href.substring(0, queryStringStartIndex);
      if (hrefs.indexOf(href) < 0 && !href.includes(BOT_URL_GUID)) {
        campaignContentLinks.push({ value: decodeURI(href), display: decodeURI(href) });
        hrefs.push(href);
      }
    }
  });
  //if atleast one link is there we need to add "any" option
  if (campaignContentLinks.length > 0) {
    campaignContentLinks.splice(0, 0, { value: "any", display: "Any" });
  }
  return campaignContentLinks;
};

export const commonIframeRender = (_iFrame, html) => {
  if (_iFrame && _iFrame.current) {
    _iFrame.current.contentWindow.document.open();
    _iFrame.current.contentWindow.document.write("");
    _iFrame.current.contentWindow.document.close();
    _iFrame.current.contentWindow.document.write(html);
  }
  return _iFrame;
};
export const filesAndFolderSort = (_items, searchTerm, sortColumn, sortOrder, _) => {
  let children;
  if (searchTerm) children = _items.children.filter((item) => item.name.toLowerCase().includes(searchTerm));
  else children = _items.children;

  let items = _.mapValues(_.groupBy(children || [], "type"), (v) =>
    _.orderBy(v, [(item) => (sortColumn == "name" ? item.name.toLowerCase() : item.size)], [sortOrder])
  );
  if (sortColumn == "dateCreated") {
    const sortedFiles = _.orderBy(
      items.file,
      (object) => {
        return new Date(object.dateCreated);
      },
      [sortOrder]
    );
    if (sortedFiles.length > 0) {
      items.file = sortedFiles;
    }
  }
  return items;
};

export const generateThumbnail = (generateTempThumbnail, templateId, source) => {
  if (generateTempThumbnail) {
    const data = {
      templateId,
      generateTempThumbnail,
      source
    };
    apiCall("/api/generateThumbnail", "POST", data).then((groups) => {
      //
    });
  }
};
export const getRepIDs = async (url) => {
  const resp = await apiCall(`/api/${url}`, "GET");
  const repUserList = resp.repsList.map((item) => {
    let repValue = "";
    if (item.firstName && item.lastName && item.firstName.length > 0 && item.lastName.length > 0) {
      repValue = item.firstName + " " + item.lastName;
    } else if (item.firstName && item.firstName.length > 0 && (item.lastName === undefined || item.lastName === "")) {
      repValue = item.firstName;
    } else if (item.email && item.email.length > 0) {
      repValue = item.email.indexOf("@") > -1 ? item.email.substring(0, item.indexOf("@")) : item.email;
    } else if (
      (item.firstName === undefined || item.firstName == "") &&
      (item.lastName === undefined || item.lastName == "") &&
      (item.email === undefined || item.email == "")
    ) {
      repValue = "InsufficientDetails";
    }
    if (repValue !== "") {
      return { repId: item.repUserId, repValue };
    } else {
      return { repId: item.repUserId, repValue: "No reps found" };
    }
  });
  return repUserList;
};
export const getRepNameById = (repId, repList) => {
  let repName = "";
  if (repId == undefined) return "No Rep";
  const repItem = repList.find((item) => item.repId == repId);
  if (repItem !== undefined) {
    if (repItem.repValue && repItem.repValue !== "") {
      repName = repItem.repValue;
    } else {
      repName = "N/A";
    }
  } else {
    repName = "Rep not found";
  }
  return repName;
};

export const getRepNameFromRepDetails = (repDetails) => {
  let repName = "No Rep";
  if (repDetails) {
    if (
      repDetails.firstName &&
      repDetails.lastName &&
      repDetails.firstName.length > 0 &&
      repDetails.lastName.length > 0
    ) {
      repName = repDetails.firstName + " " + repDetails.lastName;
    } else if (
      repDetails.firstName &&
      repDetails.firstName.length > 0 &&
      (repDetails.lastName === undefined || repDetails.lastName === "")
    ) {
      repName = repDetails.firstName;
    } else if (repDetails.email && repDetails.email.length > 0) {
      repName =
        repDetails.email.indexOf("@") > -1 ? repDetails.email.substring(0, repDetails.indexOf("@")) : repDetails.email;
    } else if (
      (repDetails.firstName === undefined || repDetails.firstName == "") &&
      (repDetails.lastName === undefined || repDetails.lastName == "") &&
      (repDetails.email === undefined || repDetails.email == "")
    ) {
      repName = "InsufficientDetails";
    }
  }
  return repName;
};

export const headerAssociatedCampaigns = async (
  settingId,
  headerName,
  PrilfeConfirmationAlert,
  showConfirm,
  promptType,
  message
) => {
  const apiResponse = await apiCall(`/api/checkHeaderAssociatedCampaigns?id=${settingId}`, "GET");

  let listMessage = [],
    draftCampaigns = [],
    publishedCampaigns = [],
    unPublishedCampaigns = [];
  if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
    apiResponse.associatedCampaigns.forEach((camp) => {
      if (camp.Status == "Draft") draftCampaigns.push(camp.CampaignName);
      if (camp.Status == "Published") {
        publishedCampaigns.push(camp.CampaignName);
      }
      if (camp.Status == "Unpublished") {
        unPublishedCampaigns.push(camp.CampaignName);
      }
    });
    if (draftCampaigns.length > 0) listMessage.push({ title: "Draft", list: draftCampaigns });
    if (publishedCampaigns.length > 0) listMessage.push({ title: "Published", list: publishedCampaigns });
    if (unPublishedCampaigns.length > 0) listMessage.push({ title: "Unpublished", list: unPublishedCampaigns });
    //Show Confirmation Box
    let confirmBox = PrilfeConfirmationAlert.deleteImpactAreasConfirm;
    confirmBox.title = promptType;
    confirmBox.msgText = message;
    const showDeleteHeader = await showConfirm({ ...confirmBox, list: listMessage });
    if (showDeleteHeader == false) {
      return false;
    }
  }
  return true;
};

export const footerAssociatedCampaigns = async (
  settingId,
  PrilfeConfirmationAlert,
  showConfirm,
  promptType,
  message
) => {
  const apiResponse = await apiCall(`/api/checkFooterAssociatedCampaigns?id=${settingId}`, "GET");
  if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
    let listMessage = [],
      draftCampaigns = [],
      publishedCampaigns = [],
      unPublishedCampaigns = [],
      scheduledCampaigns = [];
    if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
      apiResponse.associatedCampaigns.forEach((camp) => {
        if (camp.Status == "Draft") draftCampaigns.push(camp.CampaignName);
        else if (camp.Status == "Published") {
          publishedCampaigns.push(camp.CampaignName);
        } else if (camp.Status == "Unpublished") {
          unPublishedCampaigns.push(camp.CampaignName);
        } else scheduledCampaigns.push(camp.CampaignName);
      });
      if (draftCampaigns.length > 0) listMessage.push({ title: "Draft", list: draftCampaigns });
      if (scheduledCampaigns.length > 0) listMessage.push({ title: "Scheduled", list: scheduledCampaigns });
      if (publishedCampaigns.length > 0) listMessage.push({ title: "Published", list: publishedCampaigns });
      if (unPublishedCampaigns.length > 0) listMessage.push({ title: "Unpublished", list: unPublishedCampaigns });
    }

    //Show Confirmation Box
    let confirmBox = PrilfeConfirmationAlert.deleteImpactAreasConfirm;
    confirmBox.title = promptType;
    confirmBox.msgText = message;
    const deleteFooter = await showConfirm({ ...confirmBox, list: listMessage });
    if (deleteFooter == false) {
      return false;
    }
  }
  return true;
};

export const dynamicRSSMergeTags = async (rssFeedUrl) => {
  const response = await apiCall(`/api/getRSSXML?rssFeedUrl=${rssFeedUrl}`, "GET");
  let dynamicRSSTags = [];
  if (response && response.status == "success") {
    if (response.date && response.date.status == "success") {
      const rssItemObj = response.date && response.date.data.channel[0].item;
      let itemKeys = [];
      for (var i = 0; i < rssItemObj.length; i++) {
        Object.keys(rssItemObj[i]).forEach(function (key) {
          if (itemKeys.indexOf(key) == -1) {
            itemKeys.push(key);
          }
        });
      }
      let RSSTags = itemKeys.filter((obj1) => !RSS_FEED_MERGETAGS.some((obj2) => obj2.key === `RSS_${obj1}`));
      dynamicRSSTags = RSSTags.map((tag) => {
        return (tag = {
          name: `RSS ${tag.charAt(0).toUpperCase() + tag.slice(1)}`,
          value: `<% RSS_${tag} %>`,
          key: `RSS_${tag}`
        });
      });
    } else {
      return "error";
    }
  }
  return dynamicRSSTags;
};
export function handleProfileDataFormat(fieldsList, header, value) {
  let formattedValue = "";
  let isSubscriptionDateField = ["Subscription Start Date", "Subscription End Date"].includes(header);
  let headerNames = fieldsList.map((item) => item.displayName);
  if (headerNames.includes(header) || isSubscriptionDateField) {
    let type = isSubscriptionDateField == false ? fieldsList.find((item) => item.displayName == header).fieldType : "";
    if (type == "date" && value) {
      formattedValue = moment(new Date(value)).format("DD/MM/YYYY");
    } else if (type == "datetime-local" && value) {
      formattedValue = moment(new Date(value)).format("DD/MM/YYYY HH:mm");
    } else if (isSubscriptionDateField) {
      formattedValue = clientSpecificDate(value);
    } else {
      formattedValue = Array.isArray(value) ? value.join(", ") : value;
    }
  } else {
    formattedValue = Array.isArray(value) ? value.join(", ") : value;
  }
  return formattedValue;
}

export function rssFieldValidation(
  rssFeedUrl,
  selectedFrequency,
  articleCount,
  selectedTime,
  selectedMonthDay,
  selectedWeekDay,
  readMoreText,
  readMoreCharLimit,
  readMoreMT
) {
  let rssSaveEnable = false;
  if (!rssFeedUrl || !selectedFrequency || !articleCount || !selectedTime) {
    rssSaveEnable = false;
  } else {
    if (selectedFrequency !== "days" && !selectedMonthDay && !selectedWeekDay) {
      rssSaveEnable = false;
    } else {
      if (readMoreText && (!readMoreCharLimit || !readMoreMT)) {
        rssSaveEnable = false;
      } else if (readMoreCharLimit && (!readMoreText || !readMoreMT)) {
        rssSaveEnable = false;
      } else if (readMoreMT && (!readMoreText || !readMoreCharLimit)) {
        rssSaveEnable = false;
      } else {
        rssSaveEnable = true;
      }
    }
  }
  return rssSaveEnable;
}

export const popupPageAssociations = async (lpId, ConfirmationAlert, showConfirm, setIsLoading) => {
  let url = `/api/ppAssociatedLps?isPopUpPage=${true}`;
  const associatedResponse = await apiCall(url, "POST", {
    lpId: lpId
  });
  if (associatedResponse && associatedResponse.ppAssociatedLps && associatedResponse.ppAssociatedLps.length > 0) {
    let landingPages = [];
    associatedResponse.ppAssociatedLps.map((lp) => {
      if (lp.status == "Published") {
        landingPages.push(lp.lpName);
      }
    });
    if (landingPages.length > 0) {
      let confirmBox = ConfirmationAlert.republishLp;
      confirmBox.title = "Unpublish Pop Up Page";
      confirmBox.msgText = "To unpublish the pop-up page, please unlink all the associated landing pages listed below.";
      setIsLoading(false);
      await showConfirm({ ...confirmBox, data: landingPages });
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const tagsAsCheckBoxEnabled = (layout, fields) => {
  let isTagsAsCheckBoxEnabled =
    layout == 2 &&
    fields &&
    fields.length > 0 &&
    fields.some((field) => field.type == "tagsAsCheckbox" && field.isChecked === true);
  return isTagsAsCheckBoxEnabled;
};

export const getESAppUrl = () => {
  return `${window.location.origin}/`;
  let esAppUrl = "";
  if (process.env.REACT_APP_ENV === ENV_PRODUCTION) {
    esAppUrl = MM_DOMAIN_URL;

    if (window.location.hostname.includes(MKM_DOMAIN_URL)) esAppUrl = MKM_DOMAIN_URL;
    else if (window.location.hostname.includes(NM_DOMAIN_URL)) esAppUrl = NM_DOMAIN_URL;
    esAppUrl = `https://emailservice.${esAppUrl}.com/`;
  } else {
    esAppUrl = _EmailServiceAppUrl;
  }
  return esAppUrl;
};
export const openESPageinMMTab = (pageUrl, pageTitle) => {
  let mmPostDate = { pgUrl: getESAppUrl() + pageUrl, pgTitle: pageTitle };
  window.parent.postMessage(mmPostDate, "*");
};

export const generateURLWithPayload = (URL, myObject) => {
  const encodedPayload = encodeURIComponent(encodeURIComponent(JSON.stringify(myObject)));
  return `${URL}?mrkt_cPayload=` + encodedPayload;
};

export const extractPayloadFromURL = (URL) => {
  const queryParams = new URLSearchParams(URL);
  const payload = queryParams.get("mrkt_cPayload");
  try {
    if (!payload) return URL;
    const decodedPayload = decodeURIComponent(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch (error) {
    console.error("Error while decoding query params of new campaign payload --> ", error.message);
    return null;
  }
};

export const closeESPageinMMTab = () => {
  window.parent.postMessage("closeActTab", "*");
};

export function generateMappingArray(selectedwebsiteTagId, selectedwebsiteSubTagId, response) {
  let selectedWtAndWstIds = [];

  selectedwebsiteTagId.forEach((tagId) => {
    let found = false;
    selectedwebsiteSubTagId.forEach((subTagId) => {
      const tag = response.find((item) => item.websiteTagId === tagId);
      if (tag) {
        const subTag = tag.websiteSubTags.find((subTag) => subTag.websiteSubTagId === subTagId);
        if (subTag) {
          selectedWtAndWstIds.push({ WebsiteTagID: tagId, WebsiteSubtagID: subTagId });
          found = true;
        }
      }
    });
    if (!found) {
      selectedWtAndWstIds.push({ WebsiteTagID: tagId, WebsiteSubtagID: 0 });
    }
  });

  return selectedWtAndWstIds;
}
export const thumbnailGenOnView = (imageRef, setIsThumbnailLoaded) => {
  if (imageRef && imageRef.current) {
    const options = {
      root: null, //Use Page as root
      rootMargin: "0px",
      threshold: 0
    };
    const observer = new IntersectionObserver((entities) => {
      if (entities[0].isIntersecting) {
        setIsThumbnailLoaded(true);
      }
    }, options);
    if (observer) {
      observer.observe(imageRef.current);
    }
  }
};

export const getTotalFilePathCount = (selectedProfiles) => {
  let totalFilePathCount = 0;
  selectedProfiles.forEach((profile) => {
    if (profile.filePath) {
      if (Array.isArray(profile.filePath)) {
        totalFilePathCount += profile.filePath.length;
      } else {
        totalFilePathCount++;
      }
    }
  });
  return totalFilePathCount;
};

export const downloadSingleFile = async (filePath, fileName) => {
  let response = await axios.post(
    "/api/downloadBase64",
    { filePath, fileName },
    {
      responseType: "string"
    }
  );
  function extractExtension(fileName) {
    const extensionParts = fileName.split(".");
    const fileExtension = extensionParts.pop();
    return fileExtension.toLowerCase();
  }
  let ext = extractExtension(fileName);

  if (response.status === 200) {
    const dataUri = `data:${mimeTypes[ext]};base64,${response.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = fileName;
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    return true;
  } else {
    console.error("Request failed with status: " + response.status);
    return false;
  }
};
